import { j as n } from "./jsx-runtime-D0AQ1Mn7.js";
import { useState as N } from "react";
import { C as P, c as i } from "./react-select-creatable.esm-CPDhR2Eu.js";
import "./Accordion-DrU-sWJL.js";
import { A as h } from "./Avatar-al9EpjB_.js";
import { B as v } from "./Badge-QBVLNL8O.js";
import "./Button-CAmI0_H8.js";
import "./DropdownMenu-CRQiwey-.js";
import { I as S } from "./Icon-CtH7buGH.js";
import "./Input-CyIITBNc.js";
import "./Label-C4UDFZ-F.js";
import { T as x } from "./Text-CwTWgi3Y.js";
import { T as g } from "./Tooltip-B25WPLZY.js";
import { c as w } from "./utils-CJ9afRe1.js";
import { S as C } from "./Stack-B4QZt_R0.js";
import "./Switch-CXlkMKPd.js";
import "./Toast-4AphDOJm.js";
import "./Toggle-D-drA526.js";
import { S as L } from "./Separator.component-CUuwnhk-.js";
import { U as j } from "./UserNameText.component-CiEZoOsD.js";
import { g as M } from "./helpers-YgZtohc7.js";
const d = (e) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e), V = (e) => /* @__PURE__ */ n.jsx(i.DropdownIndicator, { ...e, children: /* @__PURE__ */ n.jsx(S, { name: "NavArrowDown", strokeWidth: 1.5, width: 20, height: 20 }) }), z = (e) => /* @__PURE__ */ n.jsxs(i.Option, { ...e, children: [
  e.data.avatar,
  /* @__PURE__ */ n.jsx(
    j,
    {
      size: "sm",
      truncate: !0,
      user: e.data.user,
      isMe: e.data.isMe,
      highlight: e.selectProps.inputValue
    }
  )
] }), E = (e) => /* @__PURE__ */ n.jsx(i.MultiValueRemove, { ...e, children: /* @__PURE__ */ n.jsx(S, { name: "Cancel", strokeWidth: 1.5, width: 20, height: 20 }) }), k = (e) => /* @__PURE__ */ n.jsx(i.GroupHeading, { ...e, children: e.children }), A = (e) => {
  if (!e.data.onLoan) {
    const s = e.options.filter((o) => O(o.label, e.selectProps.inputValue)).length;
    return /* @__PURE__ */ n.jsxs("div", { className: "flex flex-col gap-4 pl-2", children: [
      /* @__PURE__ */ n.jsxs(C, { row: !0, space: "sm", children: [
        /* @__PURE__ */ n.jsx(v, { size: "md", circle: !0, variant: "secondary", children: s }),
        /* @__PURE__ */ n.jsx(
          x,
          {
            size: "sm",
            weight: "medium",
            as: "h1",
            children: e.data.label
          }
        )
      ] }),
      /* @__PURE__ */ n.jsx("div", { className: "flex flex-col gap-2 text-sm", children: e.children })
    ] });
  }
  return /* @__PURE__ */ n.jsx(i.Group, { ...e, children: e.children });
}, I = (e) => {
  const s = e.selectProps.menuHeader;
  return /* @__PURE__ */ n.jsxs(i.Menu, { ...e, children: [
    s && /* @__PURE__ */ n.jsxs(n.Fragment, { children: [
      /* @__PURE__ */ n.jsx(x, { size: "sm", weight: "medium", className: "px-3 pt-3 pb-2", children: "Send to" }),
      /* @__PURE__ */ n.jsx(L, {})
    ] }),
    e.children
  ] });
}, $ = (e) => {
  const s = e.options.reduce((o, r) => "options" in r && r.onLoan ? o + r.options.filter((l) => O(l.label, e.selectProps.inputValue)).length : o, 0);
  return /* @__PURE__ */ n.jsxs(i.MenuList, { ...e, children: [
    s > 0 && /* @__PURE__ */ n.jsxs(C, { row: !0, space: "sm", children: [
      /* @__PURE__ */ n.jsx(v, { size: "md", circle: !0, variant: "secondary", children: s }),
      /* @__PURE__ */ n.jsx(
        x,
        {
          size: "sm",
          weight: "medium",
          as: "h1",
          children: "On Loan"
        }
      )
    ] }),
    e.children
  ] });
}, T = (e) => /* @__PURE__ */ n.jsx(i.MenuPortal, { ...e, "data-toast": "true", children: e.children }), B = (e) => /* @__PURE__ */ n.jsx(i.NoOptionsMessage, { ...e, children: /* @__PURE__ */ n.jsxs(C, { space: "sm", items: "center", justify: "center", className: "flex-1", children: [
  /* @__PURE__ */ n.jsx(S, { name: "BigExclamation", width: 64, height: 64, className: "text-black-10" }),
  /* @__PURE__ */ n.jsx(x, { className: "text-black-primary font-medium", size: "sm", children: "User not found" })
] }) }), G = (e) => /* @__PURE__ */ n.jsx(
  i.MultiValue,
  {
    ...e,
    className: d(e.data.label) ? "bg-yellow-5 border-yellow-60" : "",
    "data-email": d(e.data.label),
    children: /* @__PURE__ */ n.jsxs(g, { children: [
      /* @__PURE__ */ n.jsx(g.Trigger, { asChild: !0, children: /* @__PURE__ */ n.jsx(
        j,
        {
          size: "sm",
          truncate: !0,
          user: e.data.user,
          isMe: e.data.isMe,
          highlight: e.selectProps.inputValue
        }
      ) }),
      /* @__PURE__ */ n.jsx(g.Portal, { children: /* @__PURE__ */ n.jsx(
        g.Content,
        {
          align: "start",
          side: "bottom",
          alignOffset: 0,
          children: /* @__PURE__ */ n.jsx(
            j,
            {
              size: "sm",
              user: e.data.user,
              isMe: e.data.isMe,
              highlight: e.selectProps.inputValue
            }
          )
        }
      ) })
    ] })
  }
), R = (e, s, o) => {
  const r = e + (s ?? 0) + (o ?? 0), l = e > 0 ? `+${r - 1} more` : "";
  return s > 0 && o > 0 ? `${l} (${s} Cc, ${o} Bcc)` : s > 0 ? `${l} (${s} Cc)` : o > 0 ? `${l} (${o} Bcc)` : l;
}, D = (e) => {
  const s = e.getValue(), o = s.length, r = o + (e.selectProps.totalCc ?? 0) + (e.selectProps.totalBcc ?? 0), l = s.some((m) => d(m.value)), f = (e.selectProps.totalCCEmails ?? 0) > 0 || (e.selectProps.totalBccEmails ?? 0) > 0, t = l || f, a = s.every((m) => d(m.value)), u = ((e.selectProps.totalCCEmails ?? 0) > 0 || (e.selectProps.totalBccEmails ?? 0) > 0) && (e.selectProps.totalBcc ?? 0) === 0 && (e.selectProps.totalCc ?? 0) === 0, c = a && u;
  return /* @__PURE__ */ n.jsxs(i.ValueContainer, { ...e, children: [
    e.children,
    (r > 1 || o === 0 && r === 1) && /* @__PURE__ */ n.jsx(
      v,
      {
        variant: "secondary",
        className: w("group-focus-within/recipients:hidden bg-gray-neutral-30 truncate items-center py-1 h-5 px-2 rounded-xl gap-2 text-sm leading-5 font-normal border border-gray-neutral-80 mt-1", {
          "border-yellow-60": t,
          "border-gray-neutral-80": !t,
          "bg-yellow-5": c
        }),
        children: R(o, e.selectProps.totalCc ?? 0, e.selectProps.totalBcc ?? 0)
      }
    )
  ] });
}, y = {
  base: "border-0  rounded-none bg-white hover:cursor-text text-sm",
  focus: "",
  nonFocus: ""
}, F = "text-gray-500 pl-1 group-focus-within/recipients:py-0.5", H = "pl-1 group-focus-within/recipients:py-0.5 group-focus-within/recipients:flex", W = "p-1 gap-1 group-focus-within/recipients:pt-1 group-focus-within/recipients:flex-wrap", U = "leading-7 ml-1", q = "bg-gray-neutral-30 truncate items-center py-1 h-5 pl-2 rounded-xl pr-1 gap-2 group-focus-within/recipients:flex [&:not(:first-of-type)]:hidden border border-gray-neutral-80 group-focus-within/recipients:first:mt-0 first:mt-1 first:flex", J = "leading-6 py-0.5 ", K = "hover:text-red-800 hover:border-red-300", Q = "hidden p-1 gap-1 items-start sm:flex flex-row", X = "hidden w-0 overflow-hidden", Y = "w-0", Z = "p-1 hover:bg-gray-100 text-black-primary rounded-md cursor-pointer", _ = "mt-2 border-gray-neutral-80 border bg-white rounded-lg z-dialog", ee = "text-black-primary text-sm font-medium", b = {
  base: "text-sm aria-disabled:opacity-50 shrink-0 aria-disabled:cursor-default truncate overflow-hidden cursor-pointer rounded-md p-2 flex flex-row gap-2 items-center",
  focus: "bg-gray-neutral-30 active:bg-gray-200",
  selected: "text-gray-500"
}, te = "group/recipients", ne = "pl-2 flex flex-col gap-3 text-sm [&>div]:flex [&>div]:flex-col [&>div]:gap-2 [&>div:last-of-type]:pl-2", se = "p-3 flex flex-col gap-4 scrollbar-stable", oe = " h-60 max-h-full flex items-center justify-center", Ne = (e) => {
  const [s, o] = N(e.options), r = (t) => {
    e.onChange(t);
  }, l = (t) => {
    const a = {
      value: t,
      isMe: !1,
      user: {
        emailAddress: t,
        givenName: "",
        familyName: ""
      },
      label: t,
      onLoan: !1,
      avatar: /* @__PURE__ */ n.jsx(h, { size: "sm", children: /* @__PURE__ */ n.jsx(h.Fallback, { className: "bg-yellow-60", children: M(t) }) })
    };
    o((u) => {
      const c = u.find((p) => !p.onLoan);
      return [
        ...u.filter((p) => p.onLoan),
        c ? {
          ...c,
          options: [...c.options, a]
        } : {
          ...ae,
          options: [a]
        }
      ];
    }), e.onChange([...e.value, a]);
  }, f = (t) => ({
    value: t,
    isMe: !1,
    user: {
      givenName: t,
      familyName: ""
    },
    label: t,
    onLoan: !1,
    avatar: /* @__PURE__ */ n.jsx(h, { size: "sm", children: /* @__PURE__ */ n.jsx(h.Fallback, { className: "bg-yellow-60", children: M(t) }) })
  });
  return /* @__PURE__ */ n.jsx(
    P,
    {
      isMulti: !0,
      isValidNewOption: d,
      getNewOptionData: f,
      closeMenuOnSelect: !1,
      hideSelectedOptions: !1,
      unstyled: !0,
      styles: {
        container: (t) => ({
          ...t,
          width: "100%",
          overflow: "hidden"
        }),
        input: (t) => ({
          ...t,
          order: 99,
          "input:focus": {
            boxShadow: "none"
          }
        }),
        valueContainer: (t) => ({
          ...t,
          flexWrap: "nowrap"
        }),
        menu: (t) => ({
          ...t,
          zIndex: 1304
        }),
        // On mobile, the label will truncate automatically, so we want to
        // override that behaviour.
        multiValueLabel: (t) => ({
          ...t
        }),
        option: (t) => ({
          ...t,
          display: "flex",
          cursor: "pointer"
        }),
        menuPortal: (t) => ({
          ...t,
          zIndex: 1304,
          pointerEvents: "auto"
        }),
        control: (t) => ({
          ...t,
          transition: "none",
          borderWidth: 0,
          boxShadow: "none"
        }),
        noOptionsMessage: (t) => ({
          ...t,
          backgroundColor: "transparent"
        }),
        indicatorsContainer: (t) => ({
          ...t,
          display: void 0
        })
      },
      components: {
        Option: z,
        Menu: I,
        Group: A,
        GroupHeading: k,
        MenuList: $,
        DropdownIndicator: V,
        MultiValueRemove: E,
        ValueContainer: D,
        MenuPortal: T,
        MultiValue: G,
        NoOptionsMessage: B
      },
      classNames: {
        control: ({ isFocused: t }) => w(
          t ? y.focus : y.nonFocus,
          y.base
        ),
        placeholder: () => F,
        input: () => H,
        container: () => te,
        valueContainer: () => W,
        singleValue: () => U,
        multiValue: () => q,
        multiValueLabel: () => J,
        multiValueRemove: () => K,
        indicatorsContainer: () => Q,
        clearIndicator: () => X,
        indicatorSeparator: () => Y,
        dropdownIndicator: () => Z,
        menu: () => _,
        groupHeading: () => ee,
        group: () => ne,
        menuList: () => se,
        option: ({ isFocused: t, isSelected: a }) => w(
          t && b.focus,
          a && b.selected,
          b.base
        ),
        noOptionsMessage: () => oe
      },
      ...e,
      options: s,
      className: "z-dialog",
      menuPlacement: "auto",
      onCreateOption: l,
      menuPosition: "fixed",
      menuPortalTarget: document.body,
      openMenuOnClick: !1,
      openMenuOnFocus: !1,
      menuShouldScrollIntoView: !1,
      isOptionDisabled: (t) => e.value.some((a) => a.value === t.value),
      tabSelectsValue: !1,
      filterOption: (t, a) => a ? O(t.label, a) : t.data.onLoan,
      onChange: r
    }
  );
}, O = (e, s) => e.toLowerCase().includes(s.toLowerCase()), ae = {
  label: "Not on loan",
  options: [],
  onLoan: !1
};
export {
  Ne as default
};
