import { j as l } from "./jsx-runtime-D0AQ1Mn7.js";
import { createContext as W, forwardRef as Je, useContext as I, useState as Y, useEffect as Z, useLayoutEffect as Qe, useRef as $e } from "react";
import { t as N, c as E, b as R, g as ge, d as en, e as nn, s as _e, h as tn, i as S, f as L, j as an, k as le, l as rn, n as on, o as je } from "./format-DTUgKQ74.js";
import { a as M, b as k, s as ee, i as sn } from "./subDays-Bu_U-ddh.js";
import { b as xe } from "./Button-CAmI0_H8.js";
import { I as De } from "./Icon-CtH7buGH.js";
import { c as U } from "./utils-CJ9afRe1.js";
function j(e, t) {
  const n = N(e);
  if (isNaN(t)) return E(e, NaN);
  if (!t)
    return n;
  const a = n.getDate(), r = E(e, n.getTime());
  r.setMonth(n.getMonth() + t + 1, 0);
  const o = r.getDate();
  return a >= o ? r : (n.setFullYear(
    r.getFullYear(),
    r.getMonth(),
    a
  ), n);
}
function ie(e, t) {
  const n = t * 7;
  return M(e, n);
}
function ln(e, t) {
  return j(e, t * 12);
}
function dn(e) {
  let t;
  return e.forEach(function(n) {
    const a = N(n);
    (t === void 0 || t < a || isNaN(Number(a))) && (t = a);
  }), t || /* @__PURE__ */ new Date(NaN);
}
function un(e) {
  let t;
  return e.forEach((n) => {
    const a = N(n);
    (!t || t > a || isNaN(+a)) && (t = a);
  }), t || /* @__PURE__ */ new Date(NaN);
}
function B(e, t) {
  const n = N(e), a = N(t), r = n.getFullYear() - a.getFullYear(), o = n.getMonth() - a.getMonth();
  return r * 12 + o;
}
function cn(e, t, n) {
  const a = R(e, n), r = R(t, n), o = +a - ge(a), i = +r - ge(r);
  return Math.round((o - i) / en);
}
function de(e) {
  const t = N(e), n = t.getMonth();
  return t.setFullYear(t.getFullYear(), n + 1, 0), t.setHours(23, 59, 59, 999), t;
}
function C(e) {
  const t = N(e);
  return t.setDate(1), t.setHours(0, 0, 0, 0), t;
}
function ue(e, t) {
  var s, d, c, u;
  const n = nn(), a = (t == null ? void 0 : t.weekStartsOn) ?? ((d = (s = t == null ? void 0 : t.locale) == null ? void 0 : s.options) == null ? void 0 : d.weekStartsOn) ?? n.weekStartsOn ?? ((u = (c = n.locale) == null ? void 0 : c.options) == null ? void 0 : u.weekStartsOn) ?? 0, r = N(e), o = r.getDay(), i = (o < a ? -7 : 0) + 6 - (o - a);
  return r.setDate(r.getDate() + i), r.setHours(23, 59, 59, 999), r;
}
function Oe(e) {
  return ue(e, { weekStartsOn: 1 });
}
function fn(e) {
  const t = N(e), n = t.getFullYear(), a = t.getMonth(), r = E(e, 0);
  return r.setFullYear(n, a + 1, 0), r.setHours(0, 0, 0, 0), r.getDate();
}
function vn(e) {
  return Math.trunc(+N(e) / 1e3);
}
function mn(e) {
  const t = N(e), n = t.getMonth();
  return t.setFullYear(t.getFullYear(), n + 1, 0), t.setHours(0, 0, 0, 0), t;
}
function hn(e, t) {
  return cn(
    mn(e),
    C(e),
    t
  ) + 1;
}
function se(e, t) {
  const n = N(e), a = N(t);
  return n.getTime() > a.getTime();
}
function Pe(e, t) {
  const n = N(e), a = N(t);
  return +n < +a;
}
function ce(e, t) {
  const n = N(e), a = N(t);
  return n.getFullYear() === a.getFullYear() && n.getMonth() === a.getMonth();
}
function ne(e, t) {
  const n = N(e), a = n.getFullYear(), r = n.getDate(), o = E(e, 0);
  o.setFullYear(a, t, 15), o.setHours(0, 0, 0, 0);
  const i = fn(o);
  return n.setMonth(t, Math.min(r, i)), n;
}
function we(e, t) {
  const n = N(e);
  return isNaN(+n) ? E(e, NaN) : (n.setFullYear(t), n);
}
var y = function() {
  return y = Object.assign || function(t) {
    for (var n, a = 1, r = arguments.length; a < r; a++) {
      n = arguments[a];
      for (var o in n) Object.prototype.hasOwnProperty.call(n, o) && (t[o] = n[o]);
    }
    return t;
  }, y.apply(this, arguments);
};
function yn(e, t) {
  var n = {};
  for (var a in e) Object.prototype.hasOwnProperty.call(e, a) && t.indexOf(a) < 0 && (n[a] = e[a]);
  if (e != null && typeof Object.getOwnPropertySymbols == "function")
    for (var r = 0, a = Object.getOwnPropertySymbols(e); r < a.length; r++)
      t.indexOf(a[r]) < 0 && Object.prototype.propertyIsEnumerable.call(e, a[r]) && (n[a[r]] = e[a[r]]);
  return n;
}
function Se(e, t, n) {
  for (var a = 0, r = t.length, o; a < r; a++)
    (o || !(a in t)) && (o || (o = Array.prototype.slice.call(t, 0, a)), o[a] = t[a]);
  return e.concat(o || Array.prototype.slice.call(t));
}
function A(e) {
  return e.mode === "multiple";
}
function H(e) {
  return e.mode === "range";
}
function G(e) {
  return e.mode === "single";
}
var bn = {
  root: "rdp",
  multiple_months: "rdp-multiple_months",
  with_weeknumber: "rdp-with_weeknumber",
  vhidden: "rdp-vhidden",
  button_reset: "rdp-button_reset",
  button: "rdp-button",
  caption: "rdp-caption",
  caption_start: "rdp-caption_start",
  caption_end: "rdp-caption_end",
  caption_between: "rdp-caption_between",
  caption_label: "rdp-caption_label",
  caption_dropdowns: "rdp-caption_dropdowns",
  dropdown: "rdp-dropdown",
  dropdown_month: "rdp-dropdown_month",
  dropdown_year: "rdp-dropdown_year",
  dropdown_icon: "rdp-dropdown_icon",
  months: "rdp-months",
  month: "rdp-month",
  table: "rdp-table",
  tbody: "rdp-tbody",
  tfoot: "rdp-tfoot",
  head: "rdp-head",
  head_row: "rdp-head_row",
  head_cell: "rdp-head_cell",
  nav: "rdp-nav",
  nav_button: "rdp-nav_button",
  nav_button_previous: "rdp-nav_button_previous",
  nav_button_next: "rdp-nav_button_next",
  nav_icon: "rdp-nav_icon",
  row: "rdp-row",
  weeknumber: "rdp-weeknumber",
  cell: "rdp-cell",
  day: "rdp-day",
  day_today: "rdp-day_today",
  day_outside: "rdp-day_outside",
  day_selected: "rdp-day_selected",
  day_disabled: "rdp-day_disabled",
  day_hidden: "rdp-day_hidden",
  day_range_start: "rdp-day_range_start",
  day_range_end: "rdp-day_range_end",
  day_range_middle: "rdp-day_range_middle"
};
function pn(e, t) {
  return L(e, "LLLL y", t);
}
function gn(e, t) {
  return L(e, "d", t);
}
function _n(e, t) {
  return L(e, "LLLL", t);
}
function xn(e) {
  return "".concat(e);
}
function Dn(e, t) {
  return L(e, "cccccc", t);
}
function wn(e, t) {
  return L(e, "yyyy", t);
}
var Nn = /* @__PURE__ */ Object.freeze({
  __proto__: null,
  formatCaption: pn,
  formatDay: gn,
  formatMonthCaption: _n,
  formatWeekNumber: xn,
  formatWeekdayName: Dn,
  formatYearCaption: wn
}), Mn = function(e, t, n) {
  return L(e, "do MMMM (EEEE)", n);
}, kn = function() {
  return "Month: ";
}, Cn = function() {
  return "Go to next month";
}, jn = function() {
  return "Go to previous month";
}, On = function(e, t) {
  return L(e, "cccc", t);
}, Pn = function(e) {
  return "Week n. ".concat(e);
}, Sn = function() {
  return "Year: ";
}, Wn = /* @__PURE__ */ Object.freeze({
  __proto__: null,
  labelDay: Mn,
  labelMonthDropdown: kn,
  labelNext: Cn,
  labelPrevious: jn,
  labelWeekNumber: Pn,
  labelWeekday: On,
  labelYearDropdown: Sn
});
function In() {
  var e = "buttons", t = bn, n = tn, a = {}, r = {}, o = 1, i = {}, s = /* @__PURE__ */ new Date();
  return {
    captionLayout: e,
    classNames: t,
    formatters: Nn,
    labels: Wn,
    locale: n,
    modifiersClassNames: a,
    modifiers: r,
    numberOfMonths: o,
    styles: i,
    today: s,
    mode: "default"
  };
}
function Ln(e) {
  var t = e.fromYear, n = e.toYear, a = e.fromMonth, r = e.toMonth, o = e.fromDate, i = e.toDate;
  return a ? o = C(a) : t && (o = new Date(t, 0, 1)), r ? i = de(r) : n && (i = new Date(n, 11, 31)), {
    fromDate: o ? _e(o) : void 0,
    toDate: i ? _e(i) : void 0
  };
}
var We = W(void 0);
function Fn(e) {
  var t, n = e.initialProps, a = In(), r = Ln(n), o = r.fromDate, i = r.toDate, s = (t = n.captionLayout) !== null && t !== void 0 ? t : a.captionLayout;
  s !== "buttons" && (!o || !i) && (s = "buttons");
  var d;
  (G(n) || A(n) || H(n)) && (d = n.onSelect);
  var c = y(y(y({}, a), n), { captionLayout: s, classNames: y(y({}, a.classNames), n.classNames), components: y({}, n.components), formatters: y(y({}, a.formatters), n.formatters), fromDate: o, labels: y(y({}, a.labels), n.labels), mode: n.mode || a.mode, modifiers: y(y({}, a.modifiers), n.modifiers), modifiersClassNames: y(y({}, a.modifiersClassNames), n.modifiersClassNames), onSelect: d, styles: y(y({}, a.styles), n.styles), toDate: i });
  return l.jsx(We.Provider, { value: c, children: e.children });
}
function x() {
  var e = I(We);
  if (!e)
    throw new Error("useDayPicker must be used within a DayPickerProvider.");
  return e;
}
function Ie(e) {
  var t = x(), n = t.locale, a = t.classNames, r = t.styles, o = t.formatters.formatCaption;
  return l.jsx("div", { className: a.caption_label, style: r.caption_label, "aria-live": "polite", role: "presentation", id: e.id, children: o(e.displayMonth, { locale: n }) });
}
function Tn(e) {
  return l.jsx("svg", y({ width: "8px", height: "8px", viewBox: "0 0 120 120", "data-testid": "iconDropdown" }, e, { children: l.jsx("path", { d: "M4.22182541,48.2218254 C8.44222828,44.0014225 15.2388494,43.9273804 19.5496459,47.9996989 L19.7781746,48.2218254 L60,88.443 L100.221825,48.2218254 C104.442228,44.0014225 111.238849,43.9273804 115.549646,47.9996989 L115.778175,48.2218254 C119.998577,52.4422283 120.07262,59.2388494 116.000301,63.5496459 L115.778175,63.7781746 L67.7781746,111.778175 C63.5577717,115.998577 56.7611506,116.07262 52.4503541,112.000301 L52.2218254,111.778175 L4.22182541,63.7781746 C-0.0739418023,59.4824074 -0.0739418023,52.5175926 4.22182541,48.2218254 Z", fill: "currentColor", fillRule: "nonzero" }) }));
}
function Le(e) {
  var t, n, a = e.onChange, r = e.value, o = e.children, i = e.caption, s = e.className, d = e.style, c = x(), u = (n = (t = c.components) === null || t === void 0 ? void 0 : t.IconDropdown) !== null && n !== void 0 ? n : Tn;
  return l.jsxs("div", { className: s, style: d, children: [l.jsx("span", { className: c.classNames.vhidden, children: e["aria-label"] }), l.jsx("select", { name: e.name, "aria-label": e["aria-label"], className: c.classNames.dropdown, style: c.styles.dropdown, value: r, onChange: a, children: o }), l.jsxs("div", { className: c.classNames.caption_label, style: c.styles.caption_label, "aria-hidden": "true", children: [i, l.jsx(u, { className: c.classNames.dropdown_icon, style: c.styles.dropdown_icon })] })] });
}
function Yn(e) {
  var t, n = x(), a = n.fromDate, r = n.toDate, o = n.styles, i = n.locale, s = n.formatters.formatMonthCaption, d = n.classNames, c = n.components, u = n.labels.labelMonthDropdown;
  if (!a)
    return l.jsx(l.Fragment, {});
  if (!r)
    return l.jsx(l.Fragment, {});
  var f = [];
  if (sn(a, r))
    for (var m = C(a), h = a.getMonth(); h <= r.getMonth(); h++)
      f.push(ne(m, h));
  else
    for (var m = C(/* @__PURE__ */ new Date()), h = 0; h <= 11; h++)
      f.push(ne(m, h));
  var p = function(g) {
    var w = Number(g.target.value), D = ne(C(e.displayMonth), w);
    e.onChange(D);
  }, _ = (t = c == null ? void 0 : c.Dropdown) !== null && t !== void 0 ? t : Le;
  return l.jsx(_, { name: "months", "aria-label": u(), className: d.dropdown_month, style: o.dropdown_month, onChange: p, value: e.displayMonth.getMonth(), caption: s(e.displayMonth, { locale: i }), children: f.map(function(g) {
    return l.jsx("option", { value: g.getMonth(), children: s(g, { locale: i }) }, g.getMonth());
  }) });
}
function En(e) {
  var t, n = e.displayMonth, a = x(), r = a.fromDate, o = a.toDate, i = a.locale, s = a.styles, d = a.classNames, c = a.components, u = a.formatters.formatYearCaption, f = a.labels.labelYearDropdown, m = [];
  if (!r)
    return l.jsx(l.Fragment, {});
  if (!o)
    return l.jsx(l.Fragment, {});
  for (var h = r.getFullYear(), p = o.getFullYear(), _ = h; _ <= p; _++)
    m.push(we(an(/* @__PURE__ */ new Date()), _));
  var g = function(D) {
    var F = we(C(n), Number(D.target.value));
    e.onChange(F);
  }, w = (t = c == null ? void 0 : c.Dropdown) !== null && t !== void 0 ? t : Le;
  return l.jsx(w, { name: "years", "aria-label": f(), className: d.dropdown_year, style: s.dropdown_year, onChange: g, value: n.getFullYear(), caption: u(n, { locale: i }), children: m.map(function(D) {
    return l.jsx("option", { value: D.getFullYear(), children: u(D, { locale: i }) }, D.getFullYear());
  }) });
}
function Rn(e, t) {
  var n = Y(e), a = n[0], r = n[1], o = t === void 0 ? a : t;
  return [o, r];
}
function Bn(e) {
  var t = e.month, n = e.defaultMonth, a = e.today, r = t || n || a || /* @__PURE__ */ new Date(), o = e.toDate, i = e.fromDate, s = e.numberOfMonths, d = s === void 0 ? 1 : s;
  if (o && B(o, r) < 0) {
    var c = -1 * (d - 1);
    r = j(o, c);
  }
  return i && B(r, i) < 0 && (r = i), C(r);
}
function An() {
  var e = x(), t = Bn(e), n = Rn(t, e.month), a = n[0], r = n[1], o = function(i) {
    var s;
    if (!e.disableNavigation) {
      var d = C(i);
      r(d), (s = e.onMonthChange) === null || s === void 0 || s.call(e, d);
    }
  };
  return [a, o];
}
function Hn(e, t) {
  for (var n = t.reverseMonths, a = t.numberOfMonths, r = C(e), o = C(j(r, a)), i = B(o, r), s = [], d = 0; d < i; d++) {
    var c = j(r, d);
    s.push(c);
  }
  return n && (s = s.reverse()), s;
}
function Kn(e, t) {
  if (!t.disableNavigation) {
    var n = t.toDate, a = t.pagedNavigation, r = t.numberOfMonths, o = r === void 0 ? 1 : r, i = a ? o : 1, s = C(e);
    if (!n)
      return j(s, i);
    var d = B(n, e);
    if (!(d < o))
      return j(s, i);
  }
}
function Un(e, t) {
  if (!t.disableNavigation) {
    var n = t.fromDate, a = t.pagedNavigation, r = t.numberOfMonths, o = r === void 0 ? 1 : r, i = a ? o : 1, s = C(e);
    if (!n)
      return j(s, -i);
    var d = B(s, n);
    if (!(d <= 0))
      return j(s, -i);
  }
}
var Fe = W(void 0);
function Vn(e) {
  var t = x(), n = An(), a = n[0], r = n[1], o = Hn(a, t), i = Kn(a, t), s = Un(a, t), d = function(f) {
    return o.some(function(m) {
      return ce(f, m);
    });
  }, c = function(f, m) {
    d(f) || (m && Pe(f, m) ? r(j(f, 1 + t.numberOfMonths * -1)) : r(f));
  }, u = {
    currentMonth: a,
    displayMonths: o,
    goToMonth: r,
    goToDate: c,
    previousMonth: s,
    nextMonth: i,
    isDateDisplayed: d
  };
  return l.jsx(Fe.Provider, { value: u, children: e.children });
}
function K() {
  var e = I(Fe);
  if (!e)
    throw new Error("useNavigation must be used within a NavigationProvider");
  return e;
}
function Ne(e) {
  var t, n = x(), a = n.classNames, r = n.styles, o = n.components, i = K().goToMonth, s = function(u) {
    i(j(u, e.displayIndex ? -e.displayIndex : 0));
  }, d = (t = o == null ? void 0 : o.CaptionLabel) !== null && t !== void 0 ? t : Ie, c = l.jsx(d, { id: e.id, displayMonth: e.displayMonth });
  return l.jsxs("div", { className: a.caption_dropdowns, style: r.caption_dropdowns, children: [l.jsx("div", { className: a.vhidden, children: c }), l.jsx(Yn, { onChange: s, displayMonth: e.displayMonth }), l.jsx(En, { onChange: s, displayMonth: e.displayMonth })] });
}
function zn(e) {
  return l.jsx("svg", y({ width: "16px", height: "16px", viewBox: "0 0 120 120" }, e, { children: l.jsx("path", { d: "M69.490332,3.34314575 C72.6145263,0.218951416 77.6798462,0.218951416 80.8040405,3.34314575 C83.8617626,6.40086786 83.9268205,11.3179931 80.9992143,14.4548388 L80.8040405,14.6568542 L35.461,60 L80.8040405,105.343146 C83.8617626,108.400868 83.9268205,113.317993 80.9992143,116.454839 L80.8040405,116.656854 C77.7463184,119.714576 72.8291931,119.779634 69.6923475,116.852028 L69.490332,116.656854 L18.490332,65.6568542 C15.4326099,62.5991321 15.367552,57.6820069 18.2951583,54.5451612 L18.490332,54.3431458 L69.490332,3.34314575 Z", fill: "currentColor", fillRule: "nonzero" }) }));
}
function Zn(e) {
  return l.jsx("svg", y({ width: "16px", height: "16px", viewBox: "0 0 120 120" }, e, { children: l.jsx("path", { d: "M49.8040405,3.34314575 C46.6798462,0.218951416 41.6145263,0.218951416 38.490332,3.34314575 C35.4326099,6.40086786 35.367552,11.3179931 38.2951583,14.4548388 L38.490332,14.6568542 L83.8333725,60 L38.490332,105.343146 C35.4326099,108.400868 35.367552,113.317993 38.2951583,116.454839 L38.490332,116.656854 C41.5480541,119.714576 46.4651794,119.779634 49.602025,116.852028 L49.8040405,116.656854 L100.804041,65.6568542 C103.861763,62.5991321 103.926821,57.6820069 100.999214,54.5451612 L100.804041,54.3431458 L49.8040405,3.34314575 Z", fill: "currentColor" }) }));
}
var z = Je(function(e, t) {
  var n = x(), a = n.classNames, r = n.styles, o = [a.button_reset, a.button];
  e.className && o.push(e.className);
  var i = o.join(" "), s = y(y({}, r.button_reset), r.button);
  return e.style && Object.assign(s, e.style), l.jsx("button", y({}, e, { ref: t, type: "button", className: i, style: s }));
});
function Gn(e) {
  var t, n, a = x(), r = a.dir, o = a.locale, i = a.classNames, s = a.styles, d = a.labels, c = d.labelPrevious, u = d.labelNext, f = a.components;
  if (!e.nextMonth && !e.previousMonth)
    return l.jsx(l.Fragment, {});
  var m = c(e.previousMonth, { locale: o }), h = [
    i.nav_button,
    i.nav_button_previous
  ].join(" "), p = u(e.nextMonth, { locale: o }), _ = [
    i.nav_button,
    i.nav_button_next
  ].join(" "), g = (t = f == null ? void 0 : f.IconRight) !== null && t !== void 0 ? t : Zn, w = (n = f == null ? void 0 : f.IconLeft) !== null && n !== void 0 ? n : zn;
  return l.jsxs("div", { className: i.nav, style: s.nav, children: [!e.hidePrevious && l.jsx(z, { name: "previous-month", "aria-label": m, className: h, style: s.nav_button_previous, disabled: !e.previousMonth, onClick: e.onPreviousClick, children: r === "rtl" ? l.jsx(g, { className: i.nav_icon, style: s.nav_icon }) : l.jsx(w, { className: i.nav_icon, style: s.nav_icon }) }), !e.hideNext && l.jsx(z, { name: "next-month", "aria-label": p, className: _, style: s.nav_button_next, disabled: !e.nextMonth, onClick: e.onNextClick, children: r === "rtl" ? l.jsx(w, { className: i.nav_icon, style: s.nav_icon }) : l.jsx(g, { className: i.nav_icon, style: s.nav_icon }) })] });
}
function Me(e) {
  var t = x().numberOfMonths, n = K(), a = n.previousMonth, r = n.nextMonth, o = n.goToMonth, i = n.displayMonths, s = i.findIndex(function(p) {
    return ce(e.displayMonth, p);
  }), d = s === 0, c = s === i.length - 1, u = t > 1 && (d || !c), f = t > 1 && (c || !d), m = function() {
    a && o(a);
  }, h = function() {
    r && o(r);
  };
  return l.jsx(Gn, { displayMonth: e.displayMonth, hideNext: u, hidePrevious: f, nextMonth: r, previousMonth: a, onPreviousClick: m, onNextClick: h });
}
function qn(e) {
  var t, n = x(), a = n.classNames, r = n.disableNavigation, o = n.styles, i = n.captionLayout, s = n.components, d = (t = s == null ? void 0 : s.CaptionLabel) !== null && t !== void 0 ? t : Ie, c;
  return r ? c = l.jsx(d, { id: e.id, displayMonth: e.displayMonth }) : i === "dropdown" ? c = l.jsx(Ne, { displayMonth: e.displayMonth, id: e.id }) : i === "dropdown-buttons" ? c = l.jsxs(l.Fragment, { children: [l.jsx(Ne, { displayMonth: e.displayMonth, displayIndex: e.displayIndex, id: e.id }), l.jsx(Me, { displayMonth: e.displayMonth, displayIndex: e.displayIndex, id: e.id })] }) : c = l.jsxs(l.Fragment, { children: [l.jsx(d, { id: e.id, displayMonth: e.displayMonth, displayIndex: e.displayIndex }), l.jsx(Me, { displayMonth: e.displayMonth, id: e.id })] }), l.jsx("div", { className: a.caption, style: o.caption, children: c });
}
function Xn(e) {
  var t = x(), n = t.footer, a = t.styles, r = t.classNames.tfoot;
  return n ? l.jsx("tfoot", { className: r, style: a.tfoot, children: l.jsx("tr", { children: l.jsx("td", { colSpan: 8, children: n }) }) }) : l.jsx(l.Fragment, {});
}
function Jn(e, t, n) {
  for (var a = n ? le(/* @__PURE__ */ new Date()) : R(/* @__PURE__ */ new Date(), { locale: e, weekStartsOn: t }), r = [], o = 0; o < 7; o++) {
    var i = M(a, o);
    r.push(i);
  }
  return r;
}
function Qn() {
  var e = x(), t = e.classNames, n = e.styles, a = e.showWeekNumber, r = e.locale, o = e.weekStartsOn, i = e.ISOWeek, s = e.formatters.formatWeekdayName, d = e.labels.labelWeekday, c = Jn(r, o, i);
  return l.jsxs("tr", { style: n.head_row, className: t.head_row, children: [a && l.jsx("td", { style: n.head_cell, className: t.head_cell }), c.map(function(u, f) {
    return l.jsx("th", { scope: "col", className: t.head_cell, style: n.head_cell, "aria-label": d(u, { locale: r }), children: s(u, { locale: r }) }, f);
  })] });
}
function $n() {
  var e, t = x(), n = t.classNames, a = t.styles, r = t.components, o = (e = r == null ? void 0 : r.HeadRow) !== null && e !== void 0 ? e : Qn;
  return l.jsx("thead", { style: a.head, className: n.head, children: l.jsx(o, {}) });
}
function et(e) {
  var t = x(), n = t.locale, a = t.formatters.formatDay;
  return l.jsx(l.Fragment, { children: a(e.date, { locale: n }) });
}
var fe = W(void 0);
function nt(e) {
  if (!A(e.initialProps)) {
    var t = {
      selected: void 0,
      modifiers: {
        disabled: []
      }
    };
    return l.jsx(fe.Provider, { value: t, children: e.children });
  }
  return l.jsx(tt, { initialProps: e.initialProps, children: e.children });
}
function tt(e) {
  var t = e.initialProps, n = e.children, a = t.selected, r = t.min, o = t.max, i = function(c, u, f) {
    var m, h;
    (m = t.onDayClick) === null || m === void 0 || m.call(t, c, u, f);
    var p = !!(u.selected && r && (a == null ? void 0 : a.length) === r);
    if (!p) {
      var _ = !!(!u.selected && o && (a == null ? void 0 : a.length) === o);
      if (!_) {
        var g = a ? Se([], a) : [];
        if (u.selected) {
          var w = g.findIndex(function(D) {
            return k(c, D);
          });
          g.splice(w, 1);
        } else
          g.push(c);
        (h = t.onSelect) === null || h === void 0 || h.call(t, g, c, u, f);
      }
    }
  }, s = {
    disabled: []
  };
  a && s.disabled.push(function(c) {
    var u = o && a.length > o - 1, f = a.some(function(m) {
      return k(m, c);
    });
    return !!(u && !f);
  });
  var d = {
    selected: a,
    onDayClick: i,
    modifiers: s
  };
  return l.jsx(fe.Provider, { value: d, children: n });
}
function ve() {
  var e = I(fe);
  if (!e)
    throw new Error("useSelectMultiple must be used within a SelectMultipleProvider");
  return e;
}
function at(e, t) {
  var n = t || {}, a = n.from, r = n.to;
  return a && r ? k(r, e) && k(a, e) ? void 0 : k(r, e) ? { from: r, to: void 0 } : k(a, e) ? void 0 : se(a, e) ? { from: e, to: r } : { from: a, to: e } : r ? se(e, r) ? { from: r, to: e } : { from: e, to: r } : a ? Pe(e, a) ? { from: e, to: a } : { from: a, to: e } : { from: e, to: void 0 };
}
var me = W(void 0);
function rt(e) {
  if (!H(e.initialProps)) {
    var t = {
      selected: void 0,
      modifiers: {
        range_start: [],
        range_end: [],
        range_middle: [],
        disabled: []
      }
    };
    return l.jsx(me.Provider, { value: t, children: e.children });
  }
  return l.jsx(ot, { initialProps: e.initialProps, children: e.children });
}
function ot(e) {
  var t = e.initialProps, n = e.children, a = t.selected, r = a || {}, o = r.from, i = r.to, s = t.min, d = t.max, c = function(h, p, _) {
    var g, w;
    (g = t.onDayClick) === null || g === void 0 || g.call(t, h, p, _);
    var D = at(h, a);
    (w = t.onSelect) === null || w === void 0 || w.call(t, D, h, p, _);
  }, u = {
    range_start: [],
    range_end: [],
    range_middle: [],
    disabled: []
  };
  if (o ? (u.range_start = [o], i ? (u.range_end = [i], k(o, i) || (u.range_middle = [
    {
      after: o,
      before: i
    }
  ])) : u.range_end = [o]) : i && (u.range_start = [i], u.range_end = [i]), s && (o && !i && u.disabled.push({
    after: ee(o, s - 1),
    before: M(o, s - 1)
  }), o && i && u.disabled.push({
    after: o,
    before: M(o, s - 1)
  }), !o && i && u.disabled.push({
    after: ee(i, s - 1),
    before: M(i, s - 1)
  })), d) {
    if (o && !i && (u.disabled.push({
      before: M(o, -d + 1)
    }), u.disabled.push({
      after: M(o, d - 1)
    })), o && i) {
      var f = S(i, o) + 1, m = d - f;
      u.disabled.push({
        before: ee(o, m)
      }), u.disabled.push({
        after: M(i, m)
      });
    }
    !o && i && (u.disabled.push({
      before: M(i, -d + 1)
    }), u.disabled.push({
      after: M(i, d - 1)
    }));
  }
  return l.jsx(me.Provider, { value: { selected: a, onDayClick: c, modifiers: u }, children: n });
}
function he() {
  var e = I(me);
  if (!e)
    throw new Error("useSelectRange must be used within a SelectRangeProvider");
  return e;
}
function V(e) {
  return Array.isArray(e) ? Se([], e) : e !== void 0 ? [e] : [];
}
function it(e) {
  var t = {};
  return Object.entries(e).forEach(function(n) {
    var a = n[0], r = n[1];
    t[a] = V(r);
  }), t;
}
var O;
(function(e) {
  e.Outside = "outside", e.Disabled = "disabled", e.Selected = "selected", e.Hidden = "hidden", e.Today = "today", e.RangeStart = "range_start", e.RangeEnd = "range_end", e.RangeMiddle = "range_middle";
})(O || (O = {}));
var st = O.Selected, P = O.Disabled, lt = O.Hidden, dt = O.Today, te = O.RangeEnd, ae = O.RangeMiddle, re = O.RangeStart, ut = O.Outside;
function ct(e, t, n) {
  var a, r = (a = {}, a[st] = V(e.selected), a[P] = V(e.disabled), a[lt] = V(e.hidden), a[dt] = [e.today], a[te] = [], a[ae] = [], a[re] = [], a[ut] = [], a);
  return e.fromDate && r[P].push({ before: e.fromDate }), e.toDate && r[P].push({ after: e.toDate }), A(e) ? r[P] = r[P].concat(t.modifiers[P]) : H(e) && (r[P] = r[P].concat(n.modifiers[P]), r[re] = n.modifiers[re], r[ae] = n.modifiers[ae], r[te] = n.modifiers[te]), r;
}
var Te = W(void 0);
function ft(e) {
  var t = x(), n = ve(), a = he(), r = ct(t, n, a), o = it(t.modifiers), i = y(y({}, r), o);
  return l.jsx(Te.Provider, { value: i, children: e.children });
}
function Ye() {
  var e = I(Te);
  if (!e)
    throw new Error("useModifiers must be used within a ModifiersProvider");
  return e;
}
function vt(e) {
  return !!(e && typeof e == "object" && "before" in e && "after" in e);
}
function mt(e) {
  return !!(e && typeof e == "object" && "from" in e);
}
function ht(e) {
  return !!(e && typeof e == "object" && "after" in e);
}
function yt(e) {
  return !!(e && typeof e == "object" && "before" in e);
}
function bt(e) {
  return !!(e && typeof e == "object" && "dayOfWeek" in e);
}
function pt(e, t) {
  var n, a = t.from, r = t.to;
  if (a && r) {
    var o = S(r, a) < 0;
    o && (n = [r, a], a = n[0], r = n[1]);
    var i = S(e, a) >= 0 && S(r, e) >= 0;
    return i;
  }
  return r ? k(r, e) : a ? k(a, e) : !1;
}
function gt(e) {
  return je(e);
}
function _t(e) {
  return Array.isArray(e) && e.every(je);
}
function xt(e, t) {
  return t.some(function(n) {
    if (typeof n == "boolean")
      return n;
    if (gt(n))
      return k(e, n);
    if (_t(n))
      return n.includes(e);
    if (mt(n))
      return pt(e, n);
    if (bt(n))
      return n.dayOfWeek.includes(e.getDay());
    if (vt(n)) {
      var a = S(n.before, e), r = S(n.after, e), o = a > 0, i = r < 0, s = se(n.before, n.after);
      return s ? i && o : o || i;
    }
    return ht(n) ? S(e, n.after) > 0 : yt(n) ? S(n.before, e) > 0 : typeof n == "function" ? n(e) : !1;
  });
}
function ye(e, t, n) {
  var a = Object.keys(t).reduce(function(o, i) {
    var s = t[i];
    return xt(e, s) && o.push(i), o;
  }, []), r = {};
  return a.forEach(function(o) {
    return r[o] = !0;
  }), n && !ce(e, n) && (r.outside = !0), r;
}
function Dt(e, t) {
  for (var n = C(e[0]), a = de(e[e.length - 1]), r, o, i = n; i <= a; ) {
    var s = ye(i, t), d = !s.disabled && !s.hidden;
    if (!d) {
      i = M(i, 1);
      continue;
    }
    if (s.selected)
      return i;
    s.today && !o && (o = i), r || (r = i), i = M(i, 1);
  }
  return o || r;
}
var wt = 365;
function Ee(e, t) {
  var n = t.moveBy, a = t.direction, r = t.context, o = t.modifiers, i = t.retry, s = i === void 0 ? { count: 0, lastFocused: e } : i, d = r.weekStartsOn, c = r.fromDate, u = r.toDate, f = r.locale, m = {
    day: M,
    week: ie,
    month: j,
    year: ln,
    startOfWeek: function(g) {
      return r.ISOWeek ? le(g) : R(g, { locale: f, weekStartsOn: d });
    },
    endOfWeek: function(g) {
      return r.ISOWeek ? Oe(g) : ue(g, { locale: f, weekStartsOn: d });
    }
  }, h = m[n](e, a === "after" ? 1 : -1);
  a === "before" && c ? h = dn([c, h]) : a === "after" && u && (h = un([u, h]));
  var p = !0;
  if (o) {
    var _ = ye(h, o);
    p = !_.disabled && !_.hidden;
  }
  return p ? h : s.count > wt ? s.lastFocused : Ee(h, {
    moveBy: n,
    direction: a,
    context: r,
    modifiers: o,
    retry: y(y({}, s), { count: s.count + 1 })
  });
}
var Re = W(void 0);
function Nt(e) {
  var t = K(), n = Ye(), a = Y(), r = a[0], o = a[1], i = Y(), s = i[0], d = i[1], c = Dt(t.displayMonths, n), u = r ?? (s && t.isDateDisplayed(s)) ? s : c, f = function() {
    d(r), o(void 0);
  }, m = function(g) {
    o(g);
  }, h = x(), p = function(g, w) {
    if (r) {
      var D = Ee(r, {
        moveBy: g,
        direction: w,
        context: h,
        modifiers: n
      });
      k(r, D) || (t.goToDate(D, r), m(D));
    }
  }, _ = {
    focusedDay: r,
    focusTarget: u,
    blur: f,
    focus: m,
    focusDayAfter: function() {
      return p("day", "after");
    },
    focusDayBefore: function() {
      return p("day", "before");
    },
    focusWeekAfter: function() {
      return p("week", "after");
    },
    focusWeekBefore: function() {
      return p("week", "before");
    },
    focusMonthBefore: function() {
      return p("month", "before");
    },
    focusMonthAfter: function() {
      return p("month", "after");
    },
    focusYearBefore: function() {
      return p("year", "before");
    },
    focusYearAfter: function() {
      return p("year", "after");
    },
    focusStartOfWeek: function() {
      return p("startOfWeek", "before");
    },
    focusEndOfWeek: function() {
      return p("endOfWeek", "after");
    }
  };
  return l.jsx(Re.Provider, { value: _, children: e.children });
}
function be() {
  var e = I(Re);
  if (!e)
    throw new Error("useFocusContext must be used within a FocusProvider");
  return e;
}
function Mt(e, t) {
  var n = Ye(), a = ye(e, n, t);
  return a;
}
var pe = W(void 0);
function kt(e) {
  if (!G(e.initialProps)) {
    var t = {
      selected: void 0
    };
    return l.jsx(pe.Provider, { value: t, children: e.children });
  }
  return l.jsx(Ct, { initialProps: e.initialProps, children: e.children });
}
function Ct(e) {
  var t = e.initialProps, n = e.children, a = function(o, i, s) {
    var d, c, u;
    if ((d = t.onDayClick) === null || d === void 0 || d.call(t, o, i, s), i.selected && !t.required) {
      (c = t.onSelect) === null || c === void 0 || c.call(t, void 0, o, i, s);
      return;
    }
    (u = t.onSelect) === null || u === void 0 || u.call(t, o, o, i, s);
  }, r = {
    selected: t.selected,
    onDayClick: a
  };
  return l.jsx(pe.Provider, { value: r, children: n });
}
function Be() {
  var e = I(pe);
  if (!e)
    throw new Error("useSelectSingle must be used within a SelectSingleProvider");
  return e;
}
function jt(e, t) {
  var n = x(), a = Be(), r = ve(), o = he(), i = be(), s = i.focusDayAfter, d = i.focusDayBefore, c = i.focusWeekAfter, u = i.focusWeekBefore, f = i.blur, m = i.focus, h = i.focusMonthBefore, p = i.focusMonthAfter, _ = i.focusYearBefore, g = i.focusYearAfter, w = i.focusStartOfWeek, D = i.focusEndOfWeek, F = function(b) {
    var v, J, Q, $;
    G(n) ? (v = a.onDayClick) === null || v === void 0 || v.call(a, e, t, b) : A(n) ? (J = r.onDayClick) === null || J === void 0 || J.call(r, e, t, b) : H(n) ? (Q = o.onDayClick) === null || Q === void 0 || Q.call(o, e, t, b) : ($ = n.onDayClick) === null || $ === void 0 || $.call(n, e, t, b);
  }, q = function(b) {
    var v;
    m(e), (v = n.onDayFocus) === null || v === void 0 || v.call(n, e, t, b);
  }, X = function(b) {
    var v;
    f(), (v = n.onDayBlur) === null || v === void 0 || v.call(n, e, t, b);
  }, T = function(b) {
    var v;
    (v = n.onDayMouseEnter) === null || v === void 0 || v.call(n, e, t, b);
  }, Ae = function(b) {
    var v;
    (v = n.onDayMouseLeave) === null || v === void 0 || v.call(n, e, t, b);
  }, He = function(b) {
    var v;
    (v = n.onDayPointerEnter) === null || v === void 0 || v.call(n, e, t, b);
  }, Ke = function(b) {
    var v;
    (v = n.onDayPointerLeave) === null || v === void 0 || v.call(n, e, t, b);
  }, Ue = function(b) {
    var v;
    (v = n.onDayTouchCancel) === null || v === void 0 || v.call(n, e, t, b);
  }, Ve = function(b) {
    var v;
    (v = n.onDayTouchEnd) === null || v === void 0 || v.call(n, e, t, b);
  }, ze = function(b) {
    var v;
    (v = n.onDayTouchMove) === null || v === void 0 || v.call(n, e, t, b);
  }, Ze = function(b) {
    var v;
    (v = n.onDayTouchStart) === null || v === void 0 || v.call(n, e, t, b);
  }, Ge = function(b) {
    var v;
    (v = n.onDayKeyUp) === null || v === void 0 || v.call(n, e, t, b);
  }, qe = function(b) {
    var v;
    switch (b.key) {
      case "ArrowLeft":
        b.preventDefault(), b.stopPropagation(), n.dir === "rtl" ? s() : d();
        break;
      case "ArrowRight":
        b.preventDefault(), b.stopPropagation(), n.dir === "rtl" ? d() : s();
        break;
      case "ArrowDown":
        b.preventDefault(), b.stopPropagation(), c();
        break;
      case "ArrowUp":
        b.preventDefault(), b.stopPropagation(), u();
        break;
      case "PageUp":
        b.preventDefault(), b.stopPropagation(), b.shiftKey ? _() : h();
        break;
      case "PageDown":
        b.preventDefault(), b.stopPropagation(), b.shiftKey ? g() : p();
        break;
      case "Home":
        b.preventDefault(), b.stopPropagation(), w();
        break;
      case "End":
        b.preventDefault(), b.stopPropagation(), D();
        break;
    }
    (v = n.onDayKeyDown) === null || v === void 0 || v.call(n, e, t, b);
  }, Xe = {
    onClick: F,
    onFocus: q,
    onBlur: X,
    onKeyDown: qe,
    onKeyUp: Ge,
    onMouseEnter: T,
    onMouseLeave: Ae,
    onPointerEnter: He,
    onPointerLeave: Ke,
    onTouchCancel: Ue,
    onTouchEnd: Ve,
    onTouchMove: ze,
    onTouchStart: Ze
  };
  return Xe;
}
function Ot() {
  var e = x(), t = Be(), n = ve(), a = he(), r = G(e) ? t.selected : A(e) ? n.selected : H(e) ? a.selected : void 0;
  return r;
}
function Pt(e) {
  return Object.values(O).includes(e);
}
function St(e, t) {
  var n = [e.classNames.day];
  return Object.keys(t).forEach(function(a) {
    var r = e.modifiersClassNames[a];
    if (r)
      n.push(r);
    else if (Pt(a)) {
      var o = e.classNames["day_".concat(a)];
      o && n.push(o);
    }
  }), n;
}
function Wt(e, t) {
  var n = y({}, e.styles.day);
  return Object.keys(t).forEach(function(a) {
    var r;
    n = y(y({}, n), (r = e.modifiersStyles) === null || r === void 0 ? void 0 : r[a]);
  }), n;
}
function It(e, t, n) {
  var a, r, o, i = x(), s = be(), d = Mt(e, t), c = jt(e, d), u = Ot(), f = !!(i.onDayClick || i.mode !== "default");
  Z(function() {
    var T;
    d.outside || s.focusedDay && f && k(s.focusedDay, e) && ((T = n.current) === null || T === void 0 || T.focus());
  }, [
    s.focusedDay,
    e,
    n,
    f,
    d.outside
  ]);
  var m = St(i, d).join(" "), h = Wt(i, d), p = !!(d.outside && !i.showOutsideDays || d.hidden), _ = (o = (r = i.components) === null || r === void 0 ? void 0 : r.DayContent) !== null && o !== void 0 ? o : et, g = l.jsx(_, { date: e, displayMonth: t, activeModifiers: d }), w = {
    style: h,
    className: m,
    children: g,
    role: "gridcell"
  }, D = s.focusTarget && k(s.focusTarget, e) && !d.outside, F = s.focusedDay && k(s.focusedDay, e), q = y(y(y({}, w), (a = { disabled: d.disabled, role: "gridcell" }, a["aria-selected"] = d.selected, a.tabIndex = F || D ? 0 : -1, a)), c), X = {
    isButton: f,
    isHidden: p,
    activeModifiers: d,
    selectedDays: u,
    buttonProps: q,
    divProps: w
  };
  return X;
}
function Lt(e) {
  var t = $e(null), n = It(e.date, e.displayMonth, t);
  return n.isHidden ? l.jsx("div", { role: "gridcell" }) : n.isButton ? l.jsx(z, y({ name: "day", ref: t }, n.buttonProps)) : l.jsx("div", y({}, n.divProps));
}
function Ft(e) {
  var t = e.number, n = e.dates, a = x(), r = a.onWeekNumberClick, o = a.styles, i = a.classNames, s = a.locale, d = a.labels.labelWeekNumber, c = a.formatters.formatWeekNumber, u = c(Number(t), { locale: s });
  if (!r)
    return l.jsx("span", { className: i.weeknumber, style: o.weeknumber, children: u });
  var f = d(Number(t), { locale: s }), m = function(h) {
    r(t, n, h);
  };
  return l.jsx(z, { name: "week-number", "aria-label": f, className: i.weeknumber, style: o.weeknumber, onClick: m, children: u });
}
function Tt(e) {
  var t, n, a = x(), r = a.styles, o = a.classNames, i = a.showWeekNumber, s = a.components, d = (t = s == null ? void 0 : s.Day) !== null && t !== void 0 ? t : Lt, c = (n = s == null ? void 0 : s.WeekNumber) !== null && n !== void 0 ? n : Ft, u;
  return i && (u = l.jsx("td", { className: o.cell, style: r.cell, children: l.jsx(c, { number: e.weekNumber, dates: e.dates }) })), l.jsxs("tr", { className: o.row, style: r.row, children: [u, e.dates.map(function(f) {
    return l.jsx("td", { className: o.cell, style: r.cell, role: "presentation", children: l.jsx(d, { displayMonth: e.displayMonth, date: f }) }, vn(f));
  })] });
}
function ke(e, t, n) {
  for (var a = n != null && n.ISOWeek ? Oe(t) : ue(t, n), r = n != null && n.ISOWeek ? le(e) : R(e, n), o = S(a, r), i = [], s = 0; s <= o; s++)
    i.push(M(r, s));
  var d = i.reduce(function(c, u) {
    var f = n != null && n.ISOWeek ? rn(u) : on(u, n), m = c.find(function(h) {
      return h.weekNumber === f;
    });
    return m ? (m.dates.push(u), c) : (c.push({
      weekNumber: f,
      dates: [u]
    }), c);
  }, []);
  return d;
}
function Yt(e, t) {
  var n = ke(C(e), de(e), t);
  if (t != null && t.useFixedWeeks) {
    var a = hn(e, t);
    if (a < 6) {
      var r = n[n.length - 1], o = r.dates[r.dates.length - 1], i = ie(o, 6 - a), s = ke(ie(o, 1), i, t);
      n.push.apply(n, s);
    }
  }
  return n;
}
function Et(e) {
  var t, n, a, r = x(), o = r.locale, i = r.classNames, s = r.styles, d = r.hideHead, c = r.fixedWeeks, u = r.components, f = r.weekStartsOn, m = r.firstWeekContainsDate, h = r.ISOWeek, p = Yt(e.displayMonth, {
    useFixedWeeks: !!c,
    ISOWeek: h,
    locale: o,
    weekStartsOn: f,
    firstWeekContainsDate: m
  }), _ = (t = u == null ? void 0 : u.Head) !== null && t !== void 0 ? t : $n, g = (n = u == null ? void 0 : u.Row) !== null && n !== void 0 ? n : Tt, w = (a = u == null ? void 0 : u.Footer) !== null && a !== void 0 ? a : Xn;
  return l.jsxs("table", { id: e.id, className: i.table, style: s.table, role: "grid", "aria-labelledby": e["aria-labelledby"], children: [!d && l.jsx(_, {}), l.jsx("tbody", { className: i.tbody, style: s.tbody, children: p.map(function(D) {
    return l.jsx(g, { displayMonth: e.displayMonth, dates: D.dates, weekNumber: D.weekNumber }, D.weekNumber);
  }) }), l.jsx(w, { displayMonth: e.displayMonth })] });
}
function Rt() {
  return !!(typeof window < "u" && window.document && window.document.createElement);
}
var Bt = Rt() ? Qe : Z, oe = !1, At = 0;
function Ce() {
  return "react-day-picker-".concat(++At);
}
function Ht(e) {
  var t, n = e ?? (oe ? Ce() : null), a = Y(n), r = a[0], o = a[1];
  return Bt(function() {
    r === null && o(Ce());
  }, []), Z(function() {
    oe === !1 && (oe = !0);
  }, []), (t = e ?? r) !== null && t !== void 0 ? t : void 0;
}
function Kt(e) {
  var t, n, a = x(), r = a.dir, o = a.classNames, i = a.styles, s = a.components, d = K().displayMonths, c = Ht(a.id ? "".concat(a.id, "-").concat(e.displayIndex) : void 0), u = a.id ? "".concat(a.id, "-grid-").concat(e.displayIndex) : void 0, f = [o.month], m = i.month, h = e.displayIndex === 0, p = e.displayIndex === d.length - 1, _ = !h && !p;
  r === "rtl" && (t = [h, p], p = t[0], h = t[1]), h && (f.push(o.caption_start), m = y(y({}, m), i.caption_start)), p && (f.push(o.caption_end), m = y(y({}, m), i.caption_end)), _ && (f.push(o.caption_between), m = y(y({}, m), i.caption_between));
  var g = (n = s == null ? void 0 : s.Caption) !== null && n !== void 0 ? n : qn;
  return l.jsxs("div", { className: f.join(" "), style: m, children: [l.jsx(g, { id: c, displayMonth: e.displayMonth, displayIndex: e.displayIndex }), l.jsx(Et, { id: u, "aria-labelledby": c, displayMonth: e.displayMonth })] }, e.displayIndex);
}
function Ut(e) {
  var t = x(), n = t.classNames, a = t.styles;
  return l.jsx("div", { className: n.months, style: a.months, children: e.children });
}
function Vt(e) {
  var t, n, a = e.initialProps, r = x(), o = be(), i = K(), s = Y(!1), d = s[0], c = s[1];
  Z(function() {
    r.initialFocus && o.focusTarget && (d || (o.focus(o.focusTarget), c(!0)));
  }, [
    r.initialFocus,
    d,
    o.focus,
    o.focusTarget,
    o
  ]);
  var u = [r.classNames.root, r.className];
  r.numberOfMonths > 1 && u.push(r.classNames.multiple_months), r.showWeekNumber && u.push(r.classNames.with_weeknumber);
  var f = y(y({}, r.styles.root), r.style), m = Object.keys(a).filter(function(p) {
    return p.startsWith("data-");
  }).reduce(function(p, _) {
    var g;
    return y(y({}, p), (g = {}, g[_] = a[_], g));
  }, {}), h = (n = (t = a.components) === null || t === void 0 ? void 0 : t.Months) !== null && n !== void 0 ? n : Ut;
  return l.jsx("div", y({ className: u.join(" "), style: f, dir: r.dir, id: r.id, nonce: a.nonce, title: a.title, lang: a.lang }, m, { children: l.jsx(h, { children: i.displayMonths.map(function(p, _) {
    return l.jsx(Kt, { displayIndex: _, displayMonth: p }, _);
  }) }) }));
}
function zt(e) {
  var t = e.children, n = yn(e, ["children"]);
  return l.jsx(Fn, { initialProps: n, children: l.jsx(Vn, { children: l.jsx(kt, { initialProps: n, children: l.jsx(nt, { initialProps: n, children: l.jsx(rt, { initialProps: n, children: l.jsx(ft, { children: l.jsx(Nt, { children: t }) }) }) }) }) }) });
}
function Zt(e) {
  return l.jsx(zt, y({}, e, { children: l.jsx(Vt, { initialProps: e }) }));
}
function Gt({
  className: e,
  classNames: t,
  showOutsideDays: n = !0,
  ...a
}) {
  return /* @__PURE__ */ l.jsx(
    Zt,
    {
      showOutsideDays: n,
      className: U("p-3", e),
      classNames: {
        months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
        month: "space-y-4",
        caption: "flex justify-center pt-1 relative items-center",
        caption_label: "text-sm font-medium",
        nav: "space-x-1 flex items-center",
        nav_button: U(
          xe({ variant: "outline" }),
          "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100"
        ),
        nav_button_previous: "absolute left-1",
        nav_button_next: "absolute right-1",
        table: "w-full border-collapse space-y-1",
        head_row: "flex",
        head_cell: "text-muted-foreground rounded-md w-8 font-normal text-[0.8rem]",
        row: "flex w-full mt-2",
        cell: U(
          "relative p-0 text-center text-sm focus-within:relative focus-within:z-20 [&:has([aria-selected])]:bg-accent [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected].day-range-end)]:rounded-r-md",
          a.mode === "range" ? "[&:has(>.day-range-end)]:rounded-r-md [&:has(>.day-range-start)]:rounded-l-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md" : "[&:has([aria-selected])]:rounded-md"
        ),
        day: U(
          xe({ variant: "ghost" }),
          "h-8 w-8 p-0 font-normal aria-selected:opacity-100"
        ),
        day_range_start: "day-range-start",
        day_range_end: "day-range-end",
        day_selected: "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground",
        day_today: "bg-accent text-accent-foreground",
        day_outside: "day-outside text-muted-foreground aria-selected:bg-accent/50 aria-selected:text-muted-foreground",
        day_disabled: "text-muted-foreground opacity-50",
        day_range_middle: "aria-selected:bg-accent aria-selected:text-accent-foreground",
        day_hidden: "invisible",
        ...t
      },
      components: {
        IconLeft: () => /* @__PURE__ */ l.jsx(De, { strokeWidth: 1.5, name: "NavArrowLeft", className: "h-5 w-5" }),
        IconRight: () => /* @__PURE__ */ l.jsx(De, { strokeWidth: 1.5, name: "NavArrowRight", className: "h-5 w-5" })
      },
      ...a
    }
  );
}
Gt.displayName = "Calendar";
export {
  Gt as C
};
