import { j as e } from "./jsx-runtime-D0AQ1Mn7.js";
import { useState as s } from "react";
import "./Accordion-DrU-sWJL.js";
import "./Avatar-al9EpjB_.js";
import "./Badge-QBVLNL8O.js";
import "./Button-CAmI0_H8.js";
import "./DropdownMenu-CRQiwey-.js";
import "./Icon-CtH7buGH.js";
import { I as i } from "./Input-CyIITBNc.js";
import "./Label-C4UDFZ-F.js";
import { T as l } from "./Text-CwTWgi3Y.js";
import "./Tooltip-B25WPLZY.js";
import "./Stack-B4QZt_R0.js";
import "./Switch-CXlkMKPd.js";
import "./Toast-4AphDOJm.js";
import "./Toggle-D-drA526.js";
import { A as a } from "./AlertDialog.component-C7J7gpua.js";
import { S as n } from "./Separator.component-CUuwnhk-.js";
const b = (m) => {
  const [t, o] = s("");
  return /* @__PURE__ */ e.jsx(
    a,
    {
      onOpenChange: m.onOpenChange,
      open: m.open,
      children: /* @__PURE__ */ e.jsxs(
        a.Content,
        {
          className: "h-auto gap-3 max-w-xs rounded-md z-full-screen",
          children: [
            /* @__PURE__ */ e.jsx(a.VisuallyHidden, { children: /* @__PURE__ */ e.jsx(a.Title, { children: "Rename" }) }),
            /* @__PURE__ */ e.jsx(l, { size: "sm", weight: "medium", className: "px-3 pt-2", children: "Rename" }),
            /* @__PURE__ */ e.jsx(n, {}),
            /* @__PURE__ */ e.jsx("div", { className: "px-3", children: /* @__PURE__ */ e.jsx(
              i,
              {
                autoFocus: !0,
                "aria-label": "Rename",
                name: "name",
                onChange: (r) => o(r.target.value),
                placeholder: "New name...",
                defaultValue: m.name
              }
            ) }),
            /* @__PURE__ */ e.jsx(n, {}),
            /* @__PURE__ */ e.jsxs(a.Footer, { children: [
              /* @__PURE__ */ e.jsx(
                a.Cancel,
                {
                  className: "w-24",
                  autoFocus: !1,
                  children: "Cancel"
                }
              ),
              /* @__PURE__ */ e.jsx(
                a.Action,
                {
                  className: "w-24",
                  onClick: () => m.onRename(t),
                  disabled: !t || m.name === t,
                  children: "Save"
                }
              )
            ] })
          ]
        }
      )
    }
  );
};
export {
  b as R
};
