import { j as m } from "./jsx-runtime-D0AQ1Mn7.js";
import { forwardRef as f } from "react";
import "./Accordion-DrU-sWJL.js";
import "./Avatar-al9EpjB_.js";
import "./Badge-QBVLNL8O.js";
import "./Button-CAmI0_H8.js";
import "./DropdownMenu-CRQiwey-.js";
import "./Icon-CtH7buGH.js";
import "./Input-CyIITBNc.js";
import "./Label-C4UDFZ-F.js";
import { T as r } from "./Text-CwTWgi3Y.js";
import "./Tooltip-B25WPLZY.js";
import "./Stack-B4QZt_R0.js";
import "./Switch-CXlkMKPd.js";
import "./Toast-4AphDOJm.js";
import "./Toggle-D-drA526.js";
import { H as g } from "./HighlightText.component-C7C6qWaB.js";
const N = (i) => i ? e(i.givenName) && e(i.familyName) ? `${i.givenName} ${i.familyName}` : e(i.givenName) ? i.givenName : e(i.familyName) ? i.familyName : "name" in i && e(i.name) ? i.name : "email" in i && e(i.email) ? i.email : "emailAddress" in i && e(i.emailAddress) ? i.emailAddress : "" : "", e = (i) => i != null && i.trim().length > 0 && i !== "null" && i !== "null null", D = f((i, a) => {
  const { user: t, isMe: n, highlight: o = "", as: l = "div", ...s } = i, p = n && t.givenName ? String(t.givenName) : N(t);
  return /* @__PURE__ */ m.jsxs(r, { as: l, ref: a, ...s, children: [
    /* @__PURE__ */ m.jsx(g, { text: p, highlightText: o }),
    " ",
    n && /* @__PURE__ */ m.jsx(r, { as: "span", size: "inherit", variant: "secondary", children: "(Me)" })
  ] });
});
export {
  D as U
};
