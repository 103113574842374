import { j as t } from "./jsx-runtime-D0AQ1Mn7.js";
import { forwardRef as C, useState as u, useEffect as h } from "react";
import { B as x } from "./Button-CAmI0_H8.js";
import { I as y } from "./Icon-CtH7buGH.js";
import { T as s } from "./Tooltip-B25WPLZY.js";
import { c as b } from "./utils-CJ9afRe1.js";
function g(o) {
  return async () => {
    if (!(navigator != null && navigator.clipboard))
      return console.warn("Clipboard not supported"), !1;
    try {
      return await navigator.clipboard.writeText(o), !0;
    } catch (r) {
      return console.warn("Copy failed", r), !1;
    }
  };
}
const B = C((o, n) => {
  const [r, a] = u(!1), [i, c] = u(!1), { text: d, ...p } = o, f = g(d);
  let e = 0;
  const m = (l) => {
    l.stopPropagation(), l.preventDefault(), f(), c(!0), a(!0), clearTimeout(e), e = setTimeout(() => {
      a(!1), c(!1);
    }, 2e3);
  };
  return h(() => () => clearTimeout(e), [e]), /* @__PURE__ */ t.jsxs(
    s,
    {
      disableHoverableContent: !0,
      open: r,
      onOpenChange: a,
      children: [
        /* @__PURE__ */ t.jsx(s.Trigger, { asChild: !0, children: /* @__PURE__ */ t.jsx(
          x,
          {
            ...p,
            type: "button",
            ref: n,
            "data-ui": i ? "active" : "",
            className: b("active:bg-gray-200 data-active:text-green-flowkit", p.className),
            variant: "text",
            onClick: m,
            children: /* @__PURE__ */ t.jsx(y, { name: i ? "Check" : "Copy", width: 20, height: 20, strokeWidth: 2 })
          }
        ) }),
        /* @__PURE__ */ t.jsx(s.Content, { children: i ? "Copied" : "Copy" })
      ]
    }
  );
});
export {
  B as C
};
